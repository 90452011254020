

div.contact_background{
	display: flex;
	align-items: center;
	justify-content: center;
	/*width: 100vw;*/
	/*min-height: 100vh;*/
	background-image: url("../../assets/computer.jpeg");
	background-size: cover ;
}
div.darkener_container{
	display: flex;
	align-items: center;
	justify-content: center;
	/*width: 100vw;*/
	/*min-height: 100vh;*/
	background-color: rgba(0, 0, 0, 0.6);
}






.contact_grid{

	height: 100vh;
	width: 100vw;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.contact_text_container{
	margin: 50px 0 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	color: rgb(233, 230, 230)
}

.contact_me_bar{
	border: 0;
    width: 80%;
    height: 2px;
    background: transparent;
    background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.2), #fff, rgba(0,0,0,0.2));
    background-image: -moz-linear-gradient(left, rgba(0,0,0,0.2), #fff, rgba(0,0,0,0.2));
    background-image: -ms-linear-gradient(left, rgba(0,0,0,0.2), #fff, rgba(0,0,0,0.2));
    background-image: -o-linear-gradient(left, rgba(0,0,0,0.2), #fff, rgba(0,0,0,0.2));
}

.contact_form_container{
	
	max-width: 800px;
	max-height: 1200px;

	margin: 60px 40px 50px 40px;
	padding: 5vh 8vw 5vh 8vw;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 5px;

	color: rgb(29, 29, 29);
	background-color: rgba(236, 236, 236, 0.9);
	box-shadow: 3px 3px 8px rgb(0, 0, 0);

	font-size: 18px;
	font-weight: lighter;
	font-family: 'opensans-bold', sans-serif;
}



.contact_form_container .contact_form{
	
	height: 80vh;
	padding: 5vh 0 5vh 0;
	display:flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}

.contact_form_container .form_group{
	font-size: 16px;
	display:flex;
	flex-direction: column;
	flex: 1;
	padding: 4px 0 4px 0;
}

.contact_form_container .form_input,
.contact_form_container .form_input:active{
	max-width: 290px;
	max-height: 110px;

	color: rgb(56, 56, 56);
	border-color: transparent;
	border-radius: 3px;
	background-color: rgb(180, 179, 179);
}

.contact_form_container .form_input_error{
	background-color: rgb(212, 148, 148);
}


/*when the screen is smalllimit size:*/
@media (max-width: 620px) {
    .contact_grid {
		grid-template-columns: 1fr;
		height: auto;
	}
}
