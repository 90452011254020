


.custom_header_container {
  height:100vh;
  width:100vw;
  /*position: absolute;*/
  /*top: calc(- 7% / 2);*/
  background-image: url("../../assets/cpu_image.jpg");
  background-size: cover ;
  /*width: 100px;*/
  position:static;
  top: 0;
  z-index: 1;

  

  
}

.darkener_layer{
  background-color: rgba(0, 0, 0, 0.6);  
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  
}