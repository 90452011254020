.animate
{
	transition: all 0.1s;
	-webkit-transition: all 0.1s;
}

.action-button
{
	/*position: relative;*/
	float: left;
	
	padding: 10px 20px 10px 20px;
    margin: 10px 5px 2px 5px;
	border-radius: 10px;
	text-align: center;
	text-decoration: none;
	color: rgb(255, 255, 255);
	background-color: transparent;
	/*text-decoration: none;	*/
	letter-spacing: 1px;
	font-family: 'opensans-bold', sans-serif;
	font-size: 18px;
}
.action-button:hover
{
	background-color: #1d7da0;
	border-bottom: 5px solid #1b6180;
}

.action-button:active
{
	margin: 14px 5px 2px 5px;
	transform: translate(0px,5px);
  -webkit-transform: translate(0px,5px);
	border-bottom: 1px solid;
	border-color: transparent;
}


.blue
{
	background-color: #146E8E;
	border-bottom: 5px solid #0C445C;
	/*text-shadow: 0px -2px #10262D;*/
}
/*
.red
{
	background-color: #E74C3C;
	border-bottom: 5px solid #BD3E31;
	text-shadow: 0px -2px #BD3E31;
}

.green
{
	background-color: #82BF56;
	border-bottom: 5px solid #669644;
	text-shadow: 0px -2px #669644;
}

.yellow
{
	background-color: #F2CF66;
	border-bottom: 5px solid #D1B358;
	text-shadow: 0px -2px #D1B358;
}
*/


/*--------------------*/
