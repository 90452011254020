.proyect_container {
  display: grid;
  gap: 1%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "text image";
}


.project_text {
  padding: 8% 2% 8% 10%;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}




div.project_text .hashtags {
  margin-top: 18px;
}
div.project_text .hashtags > * {
  margin: 3px;
}


.project_text h3 {
  margin: 0 0 2px 0;
  padding: 0 0 2px 0;
}

.project_text p{

  line-height:26px;
  font-size: 18px;
  font-weight: lighter;
  letter-spacing: 2px;

  text-align: justify;
  color: rgb(182, 182, 182);
}






.project_text .image_bar {

  display: flex;
  padding: 2px 7px 2px 7px;
  /*float: "top";*/
  justify-content: space-evenly;
  align-items: center;
  background-color: #0C445C ;
  height: 53px;
  width: 90%;
  border-radius: 10px;
}
.project_text .image_bar h3 {
  margin: 0 0 0 0;
}




.project_image {
  
  padding: 8% 10% 8% 2%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.project_image img {
  /*z-index:1;*/
  max-width: 100%;
  border-radius: 8px;
  border-color:rgb(163, 146, 45);
  border-width: 5px;
}
/*
.project_image div.image {
  height: 80%;
  width: 80%;
  background-image: url("../../assets/test_pic.png");
  background-size: cover;
}
*/

/*when the screen is smalllimit size:*/
@media (max-width: 620px) {
  .proyect_container{grid-template: "text" "image";}
  .project_text{padding: 12% 8% 2% 8%;}
  div.project_text .hashtags {text-align: center;}
  .project_image{padding: 4% 8% 12% 8%;}
  .project_text h3 {text-align: center;}
  .project_text p{text-align: center;}
}

