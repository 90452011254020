
div.donate_card{
	
	
	min-width: 300px;
	max-width: 800px;
	max-height: 1200px;

	margin: 60px 40px 50px 40px;
	padding: 5vh 5vw 5vh 5vw;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 5px;

	color: rgb(29, 29, 29);
	background-color: rgb(236, 236, 236);
	box-shadow: 3px 3px 8px rgb(0, 0, 0);

	font-size: 18px;
	font-weight: lighter;
	font-family: 'opensans-bold', sans-serif;
}



div.donate_card form.contact_form{
	width: 70%;  
	
	height: 80vh;
	padding: 4vh 0 4vh 0;
	display:flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}

div.donate_card .form_group{
	font-size: 16px;
	display:flex;
	flex-direction: column;
	flex: 1;
	padding: 4px 0 4px 0;
}

div.donate_card .form_input,
div.donate_card .form_input:active{
	max-width: 290px;
	max-height: 110px;

	color: rgb(56, 56, 56);
	border-color: transparent;
	border-radius: 3px;
	background-color: rgb(180, 179, 179);
}

div.donate_card .form_input_error{
	background-color: rgb(212, 148, 148);
}


/*when the screen is smalllimit size:*/
/*
@media (max-width: 620px) {
    .contact_grid {
		grid-template-columns: 1fr;
		height: auto;
	}
}*/
