

span#about_banner_pointer{
    position:relative; 
    top:-58px;
}



div.about_container {
    padding: 7% 0 7% 0;
    color: rgb(0, 0, 0);
    background-color: #ebeeee;
    display: grid;
    grid-template-columns: 2fr 5fr;
    grid-template-rows: 5fr 
                        1fr;

    grid-template-areas: "image text"
                        "image button" ;
}


/*image section: */
div.about_image{
    padding: 0 3vw 0 4vw;
    grid-area: image;
    display: flex;
    align-items: center;
    justify-content: center;   
    flex-direction: column; 
}

div.about_image img{
    border-radius: 100%;
    width: 10px;

    
}

/*
div.about_image a{
    font-size: 14px;
}*/




div.about_tittle{
    grid-area: text;
    padding: 0 4vw 0 3vw;
    text-align: left;
    /*width: 50%;*/
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: left;
    text-align: left;
}
div.about_tittle p{

    line-height:26px;
    font-size: 18px;
    font-weight: lighter;
    letter-spacing: 2px;

    text-align: justify;
    color: rgb(85, 85, 85);
}

.about_container hr.about_bar {
    border: 0;
    width: 100%;
    height: 2px;
    background: transparent;
    background-image: -webkit-linear-gradient(left, rgba(0,0,0,0.6), rgba(0,0,0,0.3), #ebeeee);
    background-image: -moz-linear-gradient(left, rgba(0,0,0,0.6), rgba(0,0,0,0.3), #ebeeee);
    background-image: -ms-linear-gradient(left, rgba(0,0,0,0.6), rgba(0,0,0,0.3), #ebeeee);
    background-image: -o-linear-gradient(left, rgba(0,0,0,0.6), rgba(0,0,0,0.3), #ebeeee);
}






.about_container .social_icons{
    flex-direction: row;
    justify-content: left;
    display: flex;
    padding: 0px 0px 0px 0px;
    
}
.about_container .social_icons .icon{
  

    fill: rgb(48, 48, 48);
    width: 35px;
    height: auto;
    margin: 5px 5px 5px 5px;

    -webkit-transition: fill .3s ease-in-out;
    -moz-transition: fill .3s ease-in-out;
    -o-transition: fill .3s ease-in-out;
    -ms-transition: fill .3s ease-in-out;
    transition: fill .3s ease-in-out;
}

.about_container .social_icons .icon:hover{
    fill: #B29E47;;
  }
  



div.about_download{
    padding: 0 4vw 0 3vw;
    grid-area: button;
    text-decoration: none;
}
div.about_download .inline_icon{
    padding: 0 15px 0 0;
}



/*when the screen is smalllimit size:*/
@media (max-width: 820px) {
    div.about_container {
        grid-template-areas: "image"
                             "text"
                             "button";
        grid-template-columns: 1fr;
        grid-template-rows: auto; 
        
    }
    div.about_download{
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        
    }
    .about_container hr.about_bar {
        background-image: -webkit-linear-gradient(left, #ebeeee, rgba(0,0,0,0.3), #ebeeee);
        background-image: -moz-linear-gradient(left, #ebeeee, rgba(0,0,0,0.3), #ebeeee);
        background-image: -ms-linear-gradient(left, #ebeeee, rgba(0,0,0,0.3), #ebeeee);
        background-image: -o-linear-gradient(left, #ebeeee, rgba(0,0,0,0.3), #ebeeee);
    }

    div.about_tittle {
        padding: 3% 7% 3% 7%;
        
    }
    .about_container .social_icons{
        justify-content: center;
        
    }
    div.about_tittle{
        align-items: center;
    }
}