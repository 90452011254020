

div.dark_background{
	position: absolute;
	top: -15vh;
	z-index: -1;

	background-color: rgba(49, 49, 49, 0.9);
	width: 200vw;
	height: 65vh;

	transition: transform .3s cubic-bezier(0.55, 0.055, 0.675, 0.19);  
	-ms-transition: -ms-transform .3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	transform: rotate(10deg);
	-ms-transform:rotate(10deg);
    -webkit-transform:rotate(10deg);
	transform-origin: bottom; /* this redefines the reference of the rotation*/

	display: flex;
	align-content: stretch;
  	align-items: stretch;
	
}
div.light_background{
	width: 200vw;
	height: 65vh;


	transition: transform .3s cubic-bezier(0.55, 0.055, 0.675, 0.19);  
	-ms-transition: -ms-transform .3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	transform: rotate(10deg);
	-ms-transform:rotate(10deg);
    -webkit-transform:rotate(10deg);
	transform-origin: top; /* this redefines the reference of the rotation*/

	position: absolute;
	top: 49.9vh;
	z-index: -1;
	

	/*min-height: 100vh;*/
	background-color: rgba(7, 114, 214, 0.8);
}
div.horizontal{
	transform: rotate(0deg);
}
div.horizontal{
	transform: rotate(0deg);
}


div.donate_form_container{
	z-index: -1;
	position:relative;
	overflow-y: hidden;
	overflow-x: hidden;
	
	object-fit:"cover";

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	
	/*height: 100vh;*/
	min-height: 100vh;
	width: 100vw;
	

}
