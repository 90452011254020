.animate
{
	transition: all 0.1s;
	-webkit-transition: all 0.1s;
}

.loading-action-button
{
	/*position: relative;*/
	float: left;


	padding: 10px 20px 10px 20px;
    margin: 10px 5px 2px 5px;
	border-radius: 10px;
	text-align: center;
	text-decoration: none;
	color: rgb(255, 255, 255);
	background-color: transparent;
	/*text-decoration: none;	*/
	letter-spacing: 1px;
	font-family: 'opensans-bold', sans-serif;
	font-size: 18px;
}
a.loading{
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}
a.loading div{
	margin: 0 0 3px 0;
}

.loading-action-button:hover
{
	background-color: rgb(29, 125, 160);
	border-bottom: 5px solid rgb(27, 97, 128);
}

.loading-action-button:active
{
	margin: 14px 5px 2px 5px;
	transform: translate(0px,5px);
  -webkit-transform: translate(0px,5px);
	border-bottom: 1px solid;
	border-color: transparent;
}

.blue
{
	background-color: #146E8E;
	border-bottom: 5px solid #0C445C;
	/*text-shadow: 1px 2px #10262D;*/
}