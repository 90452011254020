
html {
  scroll-behavior: smooth;
  width: 100vw;
  
}


body {
  margin: 0;
  padding: 0;
  top: 0px;
  left: 0px;
  height: 100%;

  font-family: 'opensans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon{
  
  color: white;
  fill: white;
  width: 35px;
  height: auto;
  margin: 5px 5px 5px 5px;

  -webkit-transition: fill .3s ease-in-out;
  -moz-transition: fill .3s ease-in-out;
  -o-transition: fill .3s ease-in-out;
  -ms-transition: fill .3s ease-in-out;
  transition: fill .3s ease-in-out;
}

#top_pointer{
  position:relative; 
  top:-58px;
}

div.full_window {
  position: static;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  
  /*overflow: hidden;*/
}

div.centered{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon:hover{
  fill: #B29E47;;
}

:root{
  height: 100%;
}





/***************************************
**** ------ Animation clases ------ ****
***************************************/


/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */

.fade-in {
	-webkit-animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

 @-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/**
 * ----------------------------------------------
 * animation fade-in from bottom (use in letters)
 * ----------------------------------------------
 */

.fade-in-bottom {
	-webkit-animation: fade-in-bottom 1.5s ease-in-out 0.5s both;
	        animation: fade-in-bottom 1.5s ease-in-out 0.5s both;
}
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------------
 * animation flip-in from bottom (use in blocks)
 * ----------------------------------------------
 */

.flip-in-hor-bottom {
	-webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
/**
 * ----------------------------------------
 * animation fade-out
 * ----------------------------------------
 */
 .fade-out {
	-webkit-animation: fade-out 0.5s ease-out both;
	        animation: fade-out 0.5s ease-out both;
}

 @-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.fade-in-bottom {
	-webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}